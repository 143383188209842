<template lang="pug">
structure-h1(:title="$t('dashboard.title1')")
.grid.grid-cols-3.gap-4
  structure-card
    b {{ $t('dashboard.box1_title') }}
    h3 {{ $store.getters['eans/totalEans'] }}
  structure-card
    b {{ $t('dashboard.box2_title') }}
    h3 {{ $store.getters['eans/totalEansUsed'] }}
  structure-card
    b {{ $t('dashboard.box3_title') }}
    h3 {{ $store.getters['eans/totalEansNotUsed'] }}
structure-card.relative
  font-awesome-icon.bulb(:icon="['fas', 'lightbulb']")
  transition(name="fade", mode="out-in")
    .info(v-if="show == 1", key="1") {{ $t('dashboard.msg_1') }}
    .info(v-else-if="show == 2", key="2") {{ $t('dashboard.msg_2') }}
    .info(v-else-if="show == 3", key="3") {{ $t('dashboard.msg_3') }}
    .info(v-else-if="show == 4", key="4") {{ $t('dashboard.msg_4') }}
.grid.grid-cols-3.gap-4
  router-link(:to="{ name: 'Orders' }")
    structure-button.text-white.bg-blue-600(:text="$t('globals.orders')")
  router-link(:to="{ name: 'Eans' }")
    structure-button.text-white.bg-green-500(:text="$t('globals.eans')")
  router-link(:to="{ name: 'Billing' }")
    structure-button.text-white.bg-yellow-500(:text="$t('globals.invoices')")
</template>
<script>
export default {
  name: "dashboard",
  data: () => ({
    show: 1,
  }),

  mounted() {
    this.initSlider();
  },
  methods: {
    async initSlider() {
      setInterval(() => {
        if (this.show == 4) this.show = 0;
        this.show = this.show + 1;
      }, 5000);
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  @apply text-gray-400 text-center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bulb {
  font-size: 32px;
  position: absolute;
  left: -5px;
  top: -5px;
  transform: rotate(-15deg);
  @apply text-yellow-300;
}

h3 {
  @apply font-bold text-gray-500 text-right text-lg;
}
</style>